<main class="main-page">
    <div class="main-page__header">
        <es-header></es-header>
    </div>
    <div class="main-page__content" esMainContent id="page">
        <es-polls-container></es-polls-container>

        <router-outlet></router-outlet>
    </div>
</main>
