import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { PollsContainerComponent } from '../../components/polls-container/polls-container.component';
import { MainContentDirective } from '../../directives/main-content.directive';
import { HeaderComponent } from '../../components/header/header.component';

@Component({
    templateUrl: './main-page.component.html',
    styleUrls: ['./main-page.component.scss'],
    standalone: true,
    imports: [HeaderComponent, MainContentDirective, PollsContainerComponent, RouterOutlet],
})
export class MainPageComponent implements OnDestroy {
    constructor(
        private store: Store,
        private route: ActivatedRoute
    ) {}

    public ngOnDestroy(): void {
        const name = this.route.snapshot.data.name;

        if (name) {
            // const states = {
            //     lector: [LectorPageState],
            //     admin: [AdminPageState],
            // };
            //
            // if (states[name]) {
            //     this.store.dispatch(new StateReset(...states[name]));
            // }
        }
    }
}
